import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { AlignContent } from "./shared/container.style"
import Background from "../icons/background_image.svg"
import Window from "../icons/window.svg"
import { Link } from "gatsby"

const Container = styled.div`
  display: grid;
  width: 100%;
  height: max-content;
  box-sizing: border-box;
  place-content: center;
  border-bottom: 1px solid gainsboro;
  overflow-x: hidden;
`

const SpecialContainerHero = styled.div`
  display: grid;
  height: max-content;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "content-area";
  width: 100vw;

  .icon-pc {
    @media (max-width: 767px) {
      align-self: center;
    }
  }

  .img-wrapper {
    width: 100%;
    height: 100%;
    @media (max-width: 767px) {
      align-self: center;
    }
  }

  .background-img {
    grid-area: content-area;
    z-index: 0;
    place-self: start end;

    @media (max-width: 767px) {
      position: relative;
      left: 30%;
    }

    @media (max-width: 520px) {
      position: relative;
      left: 50%;
    }
    @media (max-width: 480px) {
      width: 100%;
    }
  }

  .content {
    grid-area: content-area;
    z-index: 2;
    place-self: center;
    height: max-content;
  }

  .green {
    background-color: #00ca42;
    border: 3px solid #00ca42;
    margin-bottom: 20px;

    @media (max-width: 767px) {
      width: 210px;
      height: 60px;
    }
    @media (max-width: 475px) {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      width: 228px;
      height: 50px;
    }

    :hover {
      background-color: limegreen;
      border: 3px solid limegreen;
    }
  }

  @media (max-width: 480px) {
    overflow-x: hidden;
  }
`

const AlignContentHero = styled(AlignContent)`
  justify-content: space-between;
  align-items: center;
  height: 90%;
  width: 1440px;

  @media (max-width: 1600px) {
    width: 90%;
  }

  @media (max-width: 1060px) {
    margin: 24px 0px;
  }

  @media (max-width: 767px) {
    align-items: flex-start;
    width: 95%;
  }
`

const TextHolder = styled.div`
  display: flex;
  height: max-content;
  width: 40%;
  flex-direction: column;
  box-sizing: border-box;

  @media (max-width: 767px) {
    width: 80%;
  }
`

const TitleText = styled.h1`
  width: 540px;
  height: max-content;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 52px;
  line-height: 58px;
  color: #111111;

  .bold-title {
    font-weight: 600;
  }
  .red-title {
    color: #e44331;
    font-weight: 600;
  }

  @media (min-width: 767px) {
    @media (max-width: 1160px) {
      width: 100%;
      font-size: 40px;
      line-height: 45px;
    }
  }
  @media (max-width: 767px) {
    width: 100%;
    font-size: 32px;
    line-height: 36px;
  }
`

const Description = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #222222;

  @media (min-width: 767px) {
    @media (max-width: 1160px) {
      .second-desc {
        width: 324px;
      }
    }
  }
`

const ButtonHolder = styled.div`
  display: flex;
  flex-direction: column;
  width: 510px;
  height: max-content;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  color: #222222;

  @media (max-width: 530px) {
    width: 100%;
  }
`

const Button = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 9999px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  text-decoration: none;
  text-align: center;
  color: #ffffff;
  width: max-content;
  height: max-content;
  padding: 0.5rem 1rem;
  box-shadow: 0px 4px 16px #d6dbe3;
  @media (max-width: 1060px) {
    font-family: Work Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
  }

  @media (max-width: 767px) {
    font-family: Work Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
  }

  &:hover {
    color: white;
  }
`

function NewHero(props) {
  return (
    <Container>
      <SpecialContainerHero>
        <Background className="background-img" />
        <AlignContentHero className="content">
          <TextHolder>
            <TitleText>
              <div className="bold-title">PDF Pro:</div>
              <div className="thin-title">The All-In-One</div>
              <div className="red-title">PDF Solution</div>
            </TitleText>
            <Description>
              <Description as="p">
                Working with PDF files has never been easier, from editing PDF
                files, to merging, to exporting to Word.
              </Description>
              <Description as="p" className="second-desc">
                We offer an elegant and affordable alternative to Adobe Acrobat
                for Windows users, that is trusted by customers worldwide.
              </Description>
            </Description>
            <ButtonHolder>
              {props.ready && !props.upgrade && (
                <Button to="#" className="green" onClick={props.open}>
                  Try PDF Pro
                  <Window
                    className="windowIcon"
                    style={{
                      position: `relative`,
                      left: `0.5rem`,
                    }}
                  />
                </Button>
              )}
              {props.ready && props.upgrade && (
                <Button to="/checkout" className="green">
                  Upgrade
                  <Window
                    className="windowIcon"
                    style={{
                      position: `relative`,
                      left: `0.5rem`,
                    }}
                  />
                </Button>
              )}
              {!props.ready && (
                <Button
                  to="#"
                  className="green"
                  style={{ visibility: `hidden` }}
                  onClick={props.open}
                >
                  Try PDF Pro
                  <Window
                    className="windowIcon"
                    style={{
                      position: `relative`,
                      left: `0.5rem`,
                    }}
                  />
                </Button>
              )}
              <ButtonHolder as="p">
                Compatible with Windows 11/10/8/7
              </ButtonHolder>
            </ButtonHolder>
          </TextHolder>
          <StaticImage
            src="../images/PC-icon-v2.png"
            quality={95}
            width={852}
            loading="eager"
            alt="PC icon"
            className="icon-pc"
            imgClassName="img-wrapper"
            breakpoints={["750", "1080", "1366", "1920"]}
            objectFit="center"
          />
        </AlignContentHero>
      </SpecialContainerHero>
    </Container>
  )
}

export default NewHero
