import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import "./layout.css"
import { AlignContent } from "./shared/container.style"

import Create from "../icons/IconCreate1.svg"
import Merge from "../icons/IconMerge1.svg"
import Convert from "../icons/IconConvert1.svg"
import Forms from "../icons/IconForms1.svg"
import Insert from "../icons/IconInsert1.svg"
import Edit from "../icons/IconEdit1.svg"
import Manage from "../icons/IconManage1.svg"
import Highlight from "../icons/IconHighlight1.svg"
import Watermarks from "../icons/IconWatermarks1.svg"
import Add from "../icons/IconAdd1.svg"
import Sign from "../icons/IconSign1.svg"
import Encrypt from "../icons/IconEncrypt1.svg"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fbfbfb;
  border-bottom: 1px solid gainsboro;
  width: 100%;
  height: max-content;
`

const PositionContainer = styled(AlignContent)`
  flex-direction: column;

  @media (max-width: 480px) {
    align-items: center;
  }
`
const ViewCompleteFeatureSet = styled.p`
  margin-top: 1.5rem;
  text-align: center;
  margin-bottom: 3.5rem;
`

const MainHeader = styled.h2`
  text-align: center;
  margin: 0 0 3.5rem 0;
  padding-top: 3.5rem;
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 48px;
  color: #111111;
  @media (max-width: 480px) {
    width: 90%;
  }
`

const PdfSofttwareStyle = styled.section`
  font-family: "Work Sans", sans-serif;
  display: flex;
  width: 100%;
  height: max-content;
  justify-content: space-between;
  overflow: hidden;

  @media (max-width: 1200px) {
    width: 100%;
    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
    }
    .columns {
      width: 100%;
    }
  }

  @media (max-width: 1024px) {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 0px;
    position: relative;
    grid-template-areas:
      "column1 column2"
      "column3 column4";

    .columns {
      h3 {
        align-self: start;
      }
    }
    .column1 {
      grid-area: column1;
      justify-self: center;
    }

    .column2 {
      grid-area: column2;
      justify-self: center;
    }

    .column3 {
      grid-area: column3;
      justify-self: center;
    }

    .column4 {
      grid-area: column4;
      justify-self: center;
    }
  }
  @media (max-width: 768px) {
    .column1,
    .column2 {
      margin-bottom: 50px;
    }
  }

  @media (max-width: 480px) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  @media (max-width: 320px) {
    width: 100%;
  }

  h3 {
    @media (max-width: 768px) {
      margin-bottom: 0;
    }
    @media (max-width: 480px) {
      align-self: flex-start;
      font-size: 24px;
      line-height: 24px;
    }
  }
  .columns {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 20rem;
    flex-basis: 17%;

    @media (max-width: 768px) {
      height: 240px;
    }
    @media (max-width: 767px) {
      width: 70%;
    }

    @media (max-width: 480px) {
      margin-bottom: 2px;
    }

    h3 {
      align-self: flex-start;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
      text-align: center;
      color: #111111;
      width: max-content;
    }
    @media (max-width: 768px) {
      h3 {
        align-self: flex-start;
      }
    }
    @media (max-width: 480px) {
      h3 {
        margin-bottom: 15px;
      }
    }

    .content {
      padding: 0;
      height: 10rem;
      margin: 1rem 0;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 0;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #222222;

      @media (max-width: 768px) {
        height: max-content;
      }
      @media (max-width: 480px) {
        font-size: 16px;
        line-height: 22px;
        height: 80px;
      }
    }
  }

  span {
    display: inline;
  }
`

const PdfSoftware = () => {
  return (
    <Container>
      <PositionContainer>
        <MainHeader className="revolutionize-h1">
          Comprehensive <span style={{ fontWeight: `500` }}>PDF Software</span>
        </MainHeader>
        <PdfSofttwareStyle>
          <div className="column1 columns">
            <h3
              style={{
                fontStyle: `normal`,
                fontWeight: `500`,
                fontsize: `24px`,
                lineHeight: `24px`,
                textAlign: `center`,
                color: `#111111`,
              }}
            >
              Create &amp; Convert
            </h3>
            <div className="content">
              <Create
                style={{
                  transform: `scale(1)`,
                  padding: `0`,
                  margin: `0`,
                  marginRight: `1rem`,
                }}
              />
              Create &amp; Convert
            </div>
            <div className="content">
              <Merge
                style={{
                  padding: `0`,
                  margin: `0`,
                  marginRight: `1rem`,
                  transform: `scale(1)`,
                }}
              />
              {"  "} Merge &amp; Split PDF files{" "}
            </div>
            <div className="content">
              <Convert
                style={{
                  padding: `0`,
                  margin: `0`,
                  marginRight: `1rem`,
                  transform: `scale(1)`,
                }}
              />
              Convert to Word, Excel,<br></br> Image, or HTML
            </div>
          </div>
          <div className="column2 columns">
            <h3
              style={{
                fontStyle: `normal`,
                fontWeight: `500`,
                fontsize: `24px`,
                lineHeight: `24px`,
                textAlign: `center`,
                color: `#111111`,
              }}
            >
              Edit
            </h3>
            <div className="content">
              <Edit
                style={{
                  padding: `0`,
                  margin: `0`,
                  marginRight: `1rem`,
                  transform: `scale(1)`,
                }}
              />
              Edit &amp; Rearrange text
            </div>
            <div className="content">
              <Insert
                style={{
                  padding: `0`,
                  margin: `0`,
                  marginRight: `1rem`,
                  transform: `scale(1)`,
                }}
              />
              Insert / Move / Resize <br></br>Images
            </div>
            <div className="content">
              <Forms
                style={{
                  padding: `0`,
                  margin: `0`,
                  marginRight: `1rem`,
                  transform: `scale(1)`,
                }}
              />
              Manage Watermarks
            </div>
          </div>
          <div className="column3 columns">
            <h3
              style={{
                fontStyle: `normal`,
                fontWeight: `500`,
                fontsize: `24px`,
                lineHeight: `24px`,
                textAlign: `center`,
                color: `#111111`,
              }}
            >
              Annotate
            </h3>
            <div className="content">
              <Watermarks
                style={{
                  padding: `0`,
                  margin: `0`,
                  marginRight: `1rem`,
                  transform: `scale(1)`,
                }}
              />
              Manage Watermarks
            </div>
            <div className="content">
              <Highlight
                style={{
                  padding: `0`,
                  margin: `0`,
                  marginRight: `1rem`,
                  transform: `scale(1)`,
                }}
              />
              {"  "}Highlight, Draw, Underline Text
            </div>
            <div className="content">
              <Manage
                style={{
                  padding: `0`,
                  margin: `0`,
                  marginRight: `1rem`,
                  transform: `scale(1)`,
                }}
              />
              Manage Attachments<br></br>
              to your PDF
            </div>
          </div>
          <div className="column4 columns">
            <h3
              style={{
                fontStyle: `normal`,
                fontWeight: `500`,
                fontsize: `24px`,
                lineHeight: `24px`,
                textAlign: `center`,
                color: `#111111`,
              }}
            >
              Secure
            </h3>
            <div className="content">
              <Encrypt
                style={{
                  padding: `0`,
                  margin: `0`,
                  marginRight: `1rem`,
                  transform: `scale(1)`,
                }}
              />
              Encrypt using Passwords
            </div>
            <div className="content">
              <Sign
                style={{
                  padding: `0`,
                  margin: `0`,
                  marginRight: `1rem`,
                  transform: `scale(0.9)`,
                }}
              />
              {"  "} Electronically Sign <br></br>your Documents
            </div>
            <div className="content">
              <Add
                style={{
                  transform: `scale(0.9)`,
                  padding: `0`,
                  margin: `0`,
                  marginRight: `1rem`,
                }}
              />
              {"  "}
              Add Digital Signatures <br></br>
              to your Files
            </div>
          </div>
        </PdfSofttwareStyle>
        <ViewCompleteFeatureSet>
          <Link
            to="/features"
            style={{
              color: `#111111`,
              fontStyle: `normal`,
              fontWeight: `300`,
              fontSize: `19px`,
              lineHeight: `19px`,
            }}
          >
            View Complete Feature Set
          </Link>
        </ViewCompleteFeatureSet>
      </PositionContainer>
    </Container>
  )
}

export default PdfSoftware
