import React from "react"
import Seo from "../components/seo"
import PdfSoftware from "../components/pdfSoftware"
import Organizations from "../components/organizations"
import Ocr from "../components/ocr"
import Benefits from "../components/benefits"
import Revolutionize from "../components/revolutionize"
import Contacts from "../components/contacts"
import { useEffect } from "react"
import TryPdf from "../components/shared/TryPdf"
import styled from "styled-components"
//Global context hook
import { GlobalAuthContext } from "../Contexts/GlobalAuthContext"
import { useContext, useState } from "react"
import Hero from "../components/hero"
import { isTrial } from "../components/helpers/dateFormating"
import { navigate } from "gatsby"
import NewFooter from "../components/shared/NewFooter"

//special element that regulates that if user clicks outside of dropdown button that button closes
const Special = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: ${({ toggle }) => (toggle === true ? "block" : "none")};
  background-color: transparent;
  z-index: 9;
`

const IndexPage = () => {
  const [isReady, setIsReady] = useState(false)
  //Context hook

  const {
    isLoggedIn,
    setIsLoggedIn,
    toggle,
    setToggle,
    setScroll,
    setUpgrade,
    upgrade,
  } = useContext(GlobalAuthContext)
  useEffect(() => {
    setIsLoggedIn(JSON.parse(localStorage.getItem("isLogged")))
  }, [isLoggedIn])

  const purchase = {
    id: "S6Fnql6ZQSqguvNLK2Re1Q",
    reference: "PDF230124-2726-69110",
    buyerReference: null,
    live: false,
    currency: "EUR",
    payoutCurrency: "EUR",
    total: 91.5,
    totalDisplay: "€91.50",
    totalInPayoutCurrency: 91.5,
    totalInPayoutCurrencyDisplay: "€91.50",
    tax: 18.3,
    taxDisplay: "€18.30",
    taxInPayoutCurrency: 18.3,
    taxInPayoutCurrencyDisplay: "€18.30",
    subtotal: 73.2,
    subtotalDisplay: "€73.20",
    subtotalInPayoutCurrency: 73.2,
    subtotalInPayoutCurrencyDisplay: "€73.20",
    discount: 44.4,
    discountDisplay: "€44.40",
    discountInPayoutCurrency: 44.4,
    discountInPayoutCurrencyDisplay: "€44.40",
    discountWithTax: 55.5,
    discountWithTaxDisplay: "€55.50",
    discountWithTaxInPayoutCurrency: 55.5,
    discountWithTaxInPayoutCurrencyDisplay: "€55.50",
    payment: {
      type: "test",
      cardEnding: "4242",
    },
    account: "DO7-lDAbRCisqq0EIEl8Kw",
    tags: {
      source: "direct",
      utm_source: "null",
      utm_content: "null",
      utm_term: "null",
      utm_campaign: "null",
      utm_medium: "null",
    },
    items: [
      {
        product: "pdf-one-year-ocr",
        quantity: 1,
        coupon: null,
        sku: null,
        subtotal: 73.2,
        subtotalDisplay: "€73.20",
        subtotalInPayoutCurrency: 73.2,
        subtotalInPayoutCurrencyDisplay: "€73.20",
        attributes: {
          custom_field_Billing: "annual",
          assign_customer_as_user: "true",
          custom_field_OCR_URL:
            "https://29c388664f8967d141fb-8548cc0479d1cf03dc138af18dcc0f1b.ssl.cf2.rackcdn.com/new/ocr/ocr.zip",
          valid_duration: "1y",
          product_short_code: "pdf",
          is_license_manager: "true",
          uuid: "8fa80ed5-008c-4622-b136-fcd67f81de6d",
        },
        discount: 44.4,
        discountDisplay: "€44.40",
        discountInPayoutCurrency: 44.4,
        discountInPayoutCurrencyDisplay: "€44.40",
        subscription: "iwcVIRafTneYyUZHtCecGw",
        fulfillments: {},
        driver: {
          type: "cross-sell",
          path: "pdf-pro-popup-pdf-pro",
        },
      },
      {
        product: "ocr",
        quantity: 1,
        coupon: null,
        sku: null,
        subtotal: 0,
        subtotalDisplay: "€0.00",
        subtotalInPayoutCurrency: 0,
        subtotalInPayoutCurrencyDisplay: "€0.00",
        attributes: {
          product_short_code: "pdf",
          feature_code: "ocr",
          uuid: "8fa80ed5-008c-4622-b136-fcd67f81de6d",
        },
        discount: 0,
        discountDisplay: "€0.00",
        discountInPayoutCurrency: 0,
        discountInPayoutCurrencyDisplay: "€0.00",
        fulfillments: {},
        driver: {
          type: "configuration",
          path: "pdf-one-year-ocr",
        },
      },
      {
        product: "edit-text-images",
        quantity: 1,
        coupon: null,
        sku: null,
        subtotal: 0,
        subtotalDisplay: "€0.00",
        subtotalInPayoutCurrency: 0,
        subtotalInPayoutCurrencyDisplay: "€0.00",
        attributes: {
          product_short_code: "pdf",
          feature_code: "edit-text-images",
          uuid: "8fa80ed5-008c-4622-b136-fcd67f81de6d",
        },
        discount: 0,
        discountDisplay: "€0.00",
        discountInPayoutCurrency: 0,
        discountInPayoutCurrencyDisplay: "€0.00",
        fulfillments: {},
        driver: {
          type: "configuration",
          path: "pdf-one-year-ocr",
        },
      },
    ],
  }

  const [myPopUp, setMyPopUp] = useState("hide")
  //close popup function
  function closePopup() {
    setMyPopUp("hide")
    setScroll("hide")
  }
  //open popup function
  function openPopup(e) {
    e.preventDefault()
    setMyPopUp("show")
    setScroll("show")

    // GA4 Event
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "pdfProButtonClick",
      category: "pdfProButtonClick",
      action: "Clicked on the Try PDF Pro Button",
      label: "Clicked on the Try PDF Pro Button - Popup",
    })

    // GA4 Event - staging
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "GA4 - Custom Events",
      eventName: "GA4 - Try PDF Pro Button",
      action: "User clicked on the Try PDF Pro Button",
    })
  }

  useEffect(() => {
    // navigate("/purchased", { state: { purchase: purchase } })

    if (isLoggedIn && isTrial()) {
      setUpgrade(true)
    } else {
      setUpgrade(false)
    }
    setIsReady(true)
  }, [isLoggedIn])

  const schemaMarkup = `<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "PDF Pro",
  "url": "https://pdfpro.com/",
  "logo": "https://user-images.githubusercontent.com/76839446/158473684-0443da7e-e47d-43ad-a846-2ba382623f02.png",
  "sameAs": "https://www.linkedin.com/company/zipitysoftware",
  "description": "The solution to all your PDF needs", 
  "legalName": "PDF Pro Software Ltd.",
  "contactPoint": {"contactType":"customer support","email":"hello@pdfpro.com","url":"https://support.pdfpro.com/hc/en-us/requests/new?_ga=2.246241732.1174751079.1663546684-729587623.1652830713"}

}
</script>`

  return (
    <>
      <Seo
        title="PDF Pro | Create, Convert, Edit, Sign, &amp; OCR PDF Files"
        special={true}
        schemaMarkup={schemaMarkup}
      />
      <Hero open={openPopup} ready={isReady} upgrade={upgrade} />
      <PdfSoftware></PdfSoftware>
      <Organizations></Organizations>
      <Ocr></Ocr>
      <Benefits></Benefits>
      <Revolutionize open={openPopup} auth={isLoggedIn}></Revolutionize>
      <NewFooter></NewFooter>
      {myPopUp === "show" && <TryPdf close={closePopup} />}
      <Special
        toggle={toggle}
        onClick={() => {
          if (toggle === true) setToggle(false)
        }}
      ></Special>
    </>
  )
}

export default IndexPage
