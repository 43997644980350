import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { Link } from "gatsby"
import Window from "../icons/window.svg"

const RevolutionizeStyle = styled.section`
  grid-area: 1/1;
  position: relative;
  place-items: center;
  display: grid;

  @media (max-width: 320px) {
    width: 100%;
  }

  .container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media (max-width: 760px) {
      width: 95%;
    }
    @media (max-width: 480px) {
      margin: 0.5rem;
      transform: scale(0.85);
      align-items: flex-start;
    }
    @media (max-width: 320px) {
      width: 100%;
    }

    h2 {
      text-align: center;
      font-style: normal;
      word-wrap: break-word;
      font-weight: 300;
      font-size: 36px;
      line-height: 46px;
      color: #111111;
      @media (max-width: 480px) {
        font-size: 28px;
        line-height: 36px;
        text-align: left;
      }
    }

    span {
      font-weight: 500;
    }

    h3 {
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 20px;
      text-align: center;
      color: #111111;
      @media (max-width: 480px) {
        font-size: 20px;
        line-height: 26px;
        text-align: left;
      }
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #222222;

      @media (max-width: 480px) {
        font-size: 14px;
        line-height: 21px;
        text-align: left;
      }
    }

    .row {
      display: flex;
      justify-content: center;
      align-items: center;
      align-items: center;

      @media (max-width: 480px) {
        flex-direction: column;
      }

      a {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 9999px;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
        text-decoration: none;
        color: #ffffff;
        text-align: center;
        width: 13rem;
        padding: 0.8rem 1.5rem;
        margin-right: 1rem;
        margin-bottom: 1rem;

        @media (max-width: 480px) {
          font-size: 18px;
          line-height: 27px;
          width: 13rem;
          .windowIcon {
            display: none;
          }
        }
      }
      .green {
        background-color: #00ca42;
        border: 3px solid #00ca42;
        :hover {
          background-color: limegreen;
          border: 3px solid limegreen;
        }
      }

      .blue {
        box-sizing: border-box;
        border: 2px solid #007de6;
        color: #007de6;
        :hover {
          color: #00aeff;
          border: 2px solid #00aeff;
        }
      }
    }
  }

  .revolutionizeBG {
    @media (max-width: 480px) {
      width: 380px !important;
    }
  }
`

const Revolutionize = props => {
  return (
    <div style={{ display: "grid", borderBottom: `1px solid gainsboro` }}>
      <StaticImage
        src="../images/revolutionize-bg.svg"
        width={1920}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF", "PNG"]}
        alt="Organization Icon"
        className="revolutionizeBG"
        placeholder="blurred"
        style={{
          gridArea: "1/1",
          padding: `0`,
          margin: `0`,
        }}
      />
      <RevolutionizeStyle>
        <div className="container">
          <h2>
            Ready to Revolutionize <br></br> the Way You{" "}
            <span>Work with PDF</span> ?
          </h2>
          <h3>
            Get Started with <span>PDF Pro</span> Today!
          </h3>
          <p>
            You may evaluate PDF Pro for free for 15 days without any obligation
            to purchase. During the evaluation,<br></br> a watermark will be
            added to any document that was created or edited. You will need to
            upgrade to a commercial license to remove this constraint.
          </p>
          <div className="row">
            {!props.auth && (
              <Link to="#" className="green" role="popup" onClick={props.open}>
                Try PDF Pro
                <Window
                  className="windowIcon"
                  style={{
                    position: `relative`,
                    left: `0.5rem`,
                  }}
                />
              </Link>
            )}
            {props.auth && (
              <Link to="/download" className="green">
                Download
                <Window
                  className="windowIcon"
                  style={{
                    position: `relative`,
                    left: `0.5rem`,
                  }}
                />
              </Link>
            )}
          </div>
        </div>
      </RevolutionizeStyle>
    </div>
  )
}

export default Revolutionize
