import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { AlignContent } from "./shared/container.style"

const PositionContainer = styled(AlignContent)`
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  overflow-x: hidden;
`

const OrganizationStyle = styled.section`
  font-family: "Work Sans", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 3.5rem;
  overflow: hidden;

  .org-img {
    width: 100%;
    height: 100%;
  }
  h1 {
    margin-bottom: 3rem !important;
  }
  @media (max-width: 768px) {
    width: 100%;
  }

  @media (max-width: 600px) {
    display: flex;
    transform: scale(0.8);
    align-items: flex-start;
    margin-bottom: 0rem;
  }

  @media (max-width: 480px) {
    display: flex;
    transform: scale(0.8);
    align-items: flex-start;
    margin-bottom: 0rem;
  }
  h1 {
    @media (max-width: 767px) {
      font-size: 28px;
      line-height: 36px;
      width: 458px;
      height: 80px;
      text-align: left !important;
    }

    @media (max-width: 650px) {
      width: 100%;
    }
    @media (max-width: 480px) {
      font-size: 28px;
      line-height: 36px;
      text-align: left !important;
      margin: 0rem !important;
    }
  }
  .container-grid {
    display: grid;
    grid-template-columns: 1fr 0.5fr 1fr 0.25fr 0.25fr 0.5fr 0.5fr 0.5fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 0px;
    position: relative;
    grid-template-areas:
      "a a b b c c c d d"
      "e f f g g h h h i";
    .a {
      grid-area: a;
      justify-self: end;
      position: relative;
      left: 1rem;
    }
    .b {
      grid-area: b;
      justify-self: end;
    }
    .c {
      grid-area: c;
      justify-self: center;
    }
    .d {
      grid-area: d;
      justify-self: start;
    }
    .e {
      grid-area: e;
      justify-self: end;
    }
    .f {
      grid-area: f;
      justify-self: start;
      position: relative;
      left: -1rem;
    }
    .g {
      grid-area: g;
      justify-self: center;
    }
    .h {
      grid-area: h;
      justify-self: center;
    }
    .i {
      grid-area: i;
    }

    @media (max-width: 1024px) {
      @media (min-width: 768px) {
        grid-template-columns: 1fr 0.5fr 1fr 0.25fr 0.25fr 0.5fr 0.5fr 0.5fr 1fr;
        grid-template-rows: 1fr 1fr;
        position: relative;
        grid-template-areas:
          "a a b b c c c d d"
          "e f f g g h h h i";
        .a {
          grid-area: a;
          justify-self: start;
          left: 0rem;
        }
        .b {
          grid-area: b;
          justify-self: end;
        }
        .c {
          grid-area: c;
          justify-self: start;
          position: relative;
          left: 0.5rem;
        }
        .d {
          grid-area: d;
          justify-self: end;
        }
      }
    }

    @media (max-width: 767px) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      position: relative;
      grid-template-areas:
        "a b c"
        "d e f"
        "g h i";
      .a {
        grid-area: a;
        justify-self: start;
        left: 0rem;
        width: auto;
      }
      .b {
        grid-area: b;
        justify-self: end;
        width: auto;
      }
      .c {
        grid-area: c;
        justify-self: start;
        position: relative;
        left: 0.5rem;
        width: auto;
      }
      .d {
        grid-area: d;
        justify-self: end;
        width: auto;
      }
      .f {
        grid-area: f;
        position: relative;
        left: 0.5rem;
      }
    }
  }
`

const Organizations = () => {
  return (
    <div style={{ borderBottom: `1px solid gainsboro`, marginBottom: `-2rem` }}>
      <OrganizationStyle>
        <PositionContainer>
          <h2
            style={{
              marginTop: `3.5rem`,
              fontWeight: `300`,
              fontStyle: `normal`,
              fontSize: `36px`,
              lineHeight: `40px`,
              textAlign: `center`,
              color: `#111111`,
              marginBottom: `54px`,
            }}
          >
            Trusted by Organizations{" "}
            <span
              style={{
                fontWeight: `500`,
                fontStyle: `normal`,
                fontSize: `36px`,
                lineHeight: `40px`,
                textAlign: `center`,
                color: `#111111`,
              }}
            >
              Around the Globe
            </span>
            , Including:
          </h2>
          <div className="container-grid">
            <div className="a">
              <StaticImage
                src="../images/Org1-icon.svg"
                width={198}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF", "PNG"]}
                alt="Organization Icon"
                className="org-img"
                placeholder="blurred"
                style={{
                  padding: `0`,
                  margin: `0`,
                  marginRight: `1rem`,
                }}
              />
            </div>
            <div className="b">
              <StaticImage
                src="../images/Org2-icon.svg"
                width={198}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF", "PNG"]}
                alt="Organization Icon"
                className="org-img"
                placeholder="blurred"
                style={{
                  padding: `0`,
                  margin: `0`,
                  marginRight: `1rem`,
                }}
              />
            </div>
            <div className="c">
              <StaticImage
                src="../images/Org3-icon.svg"
                width={198}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF", "PNG"]}
                alt="Organization Icon"
                className="org-img"
                placeholder="blurred"
                style={{
                  padding: `0`,
                  margin: `0`,
                  marginRight: `1rem`,
                }}
              />
            </div>
            <div className="d">
              <StaticImage
                src="../images/Org4-icon.svg"
                width={198}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF", "PNG"]}
                alt="Organization Icon"
                className="org-img"
                placeholder="blurred"
                style={{
                  padding: `0`,
                  margin: `0`,
                  marginRight: `1rem`,
                }}
              />
            </div>
            <div className="e">
              <StaticImage
                src="../images/Org5-icon.svg"
                width={198}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF", "PNG"]}
                alt="Organization Icon"
                className="org-img"
                placeholder="blurred"
                style={{
                  padding: `0`,
                  margin: `0`,
                  marginRight: `1rem`,
                }}
              />
            </div>
            <div className="f">
              <StaticImage
                src="../images/Org6-icon.svg"
                width={198}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF", "PNG"]}
                alt="Organization Icon"
                className="org-img"
                placeholder="blurred"
                style={{
                  padding: `0`,
                  margin: `0`,
                  marginRight: `1rem`,
                }}
              />
            </div>
            <div className="g">
              <StaticImage
                src="../images/Org7-icon.svg"
                width={198}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF", "PNG"]}
                alt="Organization Icon"
                placeholder="blurred"
                className="org-img"
                style={{
                  padding: `0`,
                  margin: `0`,
                  marginRight: `1rem`,
                }}
              />
            </div>
            <div className="h">
              <StaticImage
                src="../images/Org8-icon.svg"
                width={198}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF", "PNG"]}
                alt="Organization Icon"
                className="org-img"
                placeholder="blurred"
              />
            </div>
            <div className="i">
              <StaticImage
                src="../images/Org9-icon.svg"
                width={198}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF", "PNG"]}
                alt="Organization Icon"
                placeholder="blurred"
                className="org-img"
                style={{
                  padding: `0`,
                  margin: `0`,
                  marginRight: `1rem`,
                }}
              />
            </div>
          </div>
        </PositionContainer>
      </OrganizationStyle>
    </div>
  )
}

export default Organizations
